// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-athlete-index-js": () => import("./../../src/pages/athlete/index.js" /* webpackChunkName: "component---src-pages-athlete-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-news-index-js": () => import("./../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-notifications-index-js": () => import("./../../src/pages/notifications/index.js" /* webpackChunkName: "component---src-pages-notifications-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-training-index-js": () => import("./../../src/pages/training/index.js" /* webpackChunkName: "component---src-pages-training-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-athlete-info-js": () => import("./../../src/templates/athlete-info.js" /* webpackChunkName: "component---src-templates-athlete-info-js" */),
  "component---src-templates-coming-page-js": () => import("./../../src/templates/coming-page.js" /* webpackChunkName: "component---src-templates-coming-page-js" */),
  "component---src-templates-culture-page-js": () => import("./../../src/templates/culture-page.js" /* webpackChunkName: "component---src-templates-culture-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-post-js": () => import("./../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-notifications-post-js": () => import("./../../src/templates/notifications-post.js" /* webpackChunkName: "component---src-templates-notifications-post-js" */),
  "component---src-templates-past-game-js": () => import("./../../src/templates/past-game.js" /* webpackChunkName: "component---src-templates-past-game-js" */),
  "component---src-templates-past-games-page-js": () => import("./../../src/templates/past-games-page.js" /* webpackChunkName: "component---src-templates-past-games-page-js" */),
  "component---src-templates-tags-js": () => import("./../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

